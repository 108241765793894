<template>
  <div class="relative">
    <div
      aria-hidden="true"
      class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
    >
      <shape-contrail class="relative left-[calc(50%-11rem)] w-[36.125rem] -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
    </div>

    <div class="py-24 sm:py-32 lg:pb-40">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
          <h1 class="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
            {{ appTaglineLong }}
          </h1>

          <p class="mt-8 text-pretty text-lg font-medium text-gray-600 sm:text-xl/8">
            {{ appDescriptionLong }}
          </p>

          <div class="mt-10 flex items-center justify-center gap-x-6">
            <button-primary
              label="Essayer gratuitement"
              link="/contact?demo"
            />

            <button-secondary
              label="En savoir plus"
              link="#benefits"
              class="py-2.5 font-semibold"
            />
          </div>
        </div>

        <div class="mt-16 flow-root sm:mt-24">
          <image-slider
            :interval="6000"
            :images="sliderImages"
          />
        </div>
      </div>
    </div>

    <div
      aria-hidden="true"
      class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
    >
      <shape-contrail class="relative left-[calc(50%+3rem)] w-[36.125rem] -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ImageSliderData } from 'types/image'

const sliderImages: ImageSliderData[] = [
  {
    title: `Restaurant + ${appName}`,
    link: '/slider/restaurant.webp'
  },
  {
    title: `Boulangerie + ${appName}`,
    link: '/slider/bakery.webp'
  },
  {
    title: `Magasin + ${appName}`,
    link: '/slider/store.webp'
  }
]
</script>
