<template>
  <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
    <section-title
      v-bind="titleProps"
      class="mx-auto max-w-2xl lg:text-center"
    />

    <div
      aria-hidden="true"
      class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
    >
      <shape-contrail class="w-[72.1875rem]" />
    </div>

    <div class="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 lg:mt-24 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
      <div
        v-for="(tier, tierIndex) in tiers"
        :key="tier.name"
        :class="getClassList(tier.featured, tierIndex)"
        class="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
      >
        <h3 class="text-base/7 font-semibold text-red-600">
          {{ tier.name }}
        </h3>

        <p class="mt-4 flex items-baseline gap-x-2">
          <span class="text-5xl font-semibold tracking-tight text-gray-900">
            Fr. {{ tier.priceMonthly }}.-
          </span>

          <span class="text-base text-gray-500">
            <span class="hidden sm:inline">
              par mois, par écran
            </span>

            <span class="inline sm:hidden">
              /mois /écran
            </span>
          </span>
        </p>

        <p
          v-if="tier.description"
          class="mt-6 text-base/7 text-gray-600"
        >
          {{ tier.description }}
        </p>

        <ul
          role="list"
          class="mt-8 space-y-3 text-sm/6 text-gray-600 sm:mt-10"
        >
          <li
            v-for="feature in tier.features"
            :key="feature"
            class="flex gap-x-3"
          >
            <icon
              name="ph:check-fat-duotone"
              aria-hidden="true"
              class="h-6 w-5 flex-none text-red-600"
            />

            {{ feature }}
          </li>
        </ul>

        <ul
          role="list"
          class="pl-8 mt-3 space-y-3 text-sm/6 text-gray-600"
        >
          <li
            v-for="block in tier.blocks"
            :key="block"
            class="flex gap-x-3"
          >
            <icon
              name="ph:arrow-bend-down-right-duotone"
              aria-hidden="true"
              class="h-6 w-5 flex-none text-red-600"
            />

            {{ block }}
          </li>
        </ul>

        <p
          v-if="tier.details"
          class="mt-8 text-sm/6 text-gray-600 italic sm:mt-10"
        >
          * {{ tier.details }}
        </p>

        <button-primary
          :outline="!tier.featured"
          label="Essayer gratuitement"
          link="/contact?demo"
          class="mt-8 text-center block sm:mt-10"
        />
      </div>
    </div>

    <button-contact
      label="Un besoin sur mesure ou de nombreux écrans ?"
      link="/contact"
      class="mt-16"
    />
  </div>
</template>

<script setup lang="ts">
import type { SectionTitleProps, SectionPricingTier } from 'types/section'

const titleProps: SectionTitleProps = {
  kicker: 'Prix par écran',
  title: 'Captivez vos clients à petit prix',
  description: `${appName} est décliné en deux formules adaptées à vos besoins. La formule "Pro" a tout le nécessaire pour bien démarrer. La formule "Entreprise" a plus de possibilités pour communiquer efficacement à vos clients.`
}

const tiers: SectionPricingTier[] = [
  {
    featured: false,
    priceMonthly: 8,
    name: 'Pro',
    features: [
      'Diffusion sur 1 écran ou plus',
      'Création de nombreux diaporamas',
      'Affichage planifié (date et jour)',
      'Personnalisation du design',
      'Support par email',
      'Éditeur de contenu basique'
    ],
    blocks: [
      'Titre, paragraphe et listes',
      'Tableau et autres agencements',
      'Date, heure et compte à rebours',
      'Image, vidéo YouTube et Vimeo'
    ]
  },
  {
    featured: true,
    priceMonthly: 14,
    name: 'Entreprise',
    features: [
      'Avantages de la formule "Pro"',
      'Catalogue de modèles',
      'Stockage des images et vidéos',
      'Travail à plusieurs personnes',
      'Accompagnement en visio*',
      'Support prioritaire par email',
      'Éditeur de contenu avancé'
    ],
    blocks: [
      'Page web incorporée',
      'Prévisions météorologiques',
      'Carte géographique',
      'Diaporama d\'images',
      'Image et vidéo boostée'
    ],
    details: 'Le premier accompagnement en visio est offert.'
  }
]

function getClassList (featured: boolean, index: number): string {
  if (featured) {
    return 'relative bg-white shadow-2xl'
  }

  const isFirstTier = index === 0
  const classList = isFirstTier
    ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
    : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl'

  return `bg-white/70 sm:mx-8 lg:mx-0 ${classList}`
}
</script>
